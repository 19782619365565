import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import pose from 'react-pose';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { Icon } from '../components/icons';
import SEO from '../components/seo';
import { FlexContent } from '../components/grid';
import { MarkdownStyler, Hanger, Layout } from '../components/template.common';
import { BackButton } from '../components/back-button';
const MarkdownStyleNotice = styled(MarkdownStyler) `
  h1 {
    position: relative;
    text-align: left;
    font-size: 1rem;
    opacity: 1;
    margin-bottom: 1rem;
    max-width: 100%;
    transform: none;
    left: 0;
  }
`;
const StyledInfo = styled.div `
  position: relative;
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h1 + p {
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.2px;
  }
`;
const PoseImageWrapper = pose.div({
    enter: {
        delay: 0,
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
});
const PoseContent = pose.div({
    enter: {
        delay: 150,
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: -15,
    },
});
const NoticeTemplate = ({ data, pageContext, }) => {
    const { html, excerpt, frontmatter, fields } = data.notices;
    const { title, date, tags } = frontmatter;
    const displayImage = fields.featureImage
        ? fields.featureImage.childImageSharp.fluid
        : null;
    const { lang } = pageContext;
    const { translation } = data.translate;
    return (<React.Fragment>
      <BackButton>
        {translation.t_back_button[lang]} <Icon size={1.75} iconName="close"/>
      </BackButton>
      <Layout>
        <SEO {...{
        title,
        lang,
    }}/>
        {displayImage && <PoseImageWrapper>
          <FlexContent direction="left">
            <GatsbyImage style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
    }} fadeIn={true} 
    // @ts-ignore
    fluid={displayImage}/>
          </FlexContent>
        </PoseImageWrapper>}
        <PoseContent>
          <FlexContent>
            <StyledInfo>
              <h1>{title}</h1>
              <p>{excerpt}</p>
              <Hanger>
                <p>
                  <strong>{date}</strong>
                </p>
                {tags && tags.length > 0 && <p>{tags.join(' ')}</p>}
              </Hanger>
            </StyledInfo>
          </FlexContent>
          <FlexContent>
            <MarkdownStyleNotice lang={lang} dangerouslySetInnerHTML={{
        __html: html,
    }}/>
          </FlexContent>
        </PoseContent>
      </Layout>
    </React.Fragment>);
};
export default NoticeTemplate;
export const pageQuery = graphql `
  query BlogPostBySlug($slug: String!) {
    translate: contentJson(type: { eq: "translation" }) {
      translation {
        t_back_button {
          en
          ko
          ja
          zh
        }
      }
    }
    notices: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        featureImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      frontmatter {
        title
        date(formatString: "YYYY.MM.DD")
        tags
        featureImage
      }
    }
  }
`;
